// Generated by Framer (3f9fff0)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["H26yb7rYG"];

const serializationHash = "framer-1Ji0q"

const variantClassNames = {H26yb7rYG: "framer-v-t4kory"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "H26yb7rYG", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-t4kory", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"H26yb7rYG"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1kunpv4"} data-framer-name={"Logo"} fill={"black"} intrinsicHeight={48} intrinsicWidth={40} layoutDependency={layoutDependency} layoutId={"qOnmSK5PJ"} svg={"<svg fill=\"none\" height=\"48\" width=\"40\" xmlns=\"http://www.w3.org/2000/svg\"><path clip-rule=\"evenodd\" d=\"M40 24c0 11.046-8.954 20-20 20C10.192 44 2.033 36.94.328 27.625A20.121 20.121 0 0 1 0 24c0-.964.068-1.913.2-2.841C1.448 12.387 8.387 5.448 17.16 4.2l.044-.006a20.175 20.175 0 0 1 6.422.134C32.94 6.033 40 14.192 40 24zm-5 0c0 8.284-6.716 15-15 15-6.243 0-11.595-3.814-13.854-9.239C7.074 29.918 8.028 30 9 30c9.389 0 17-7.611 17-17 0-.972-.082-1.926-.238-2.854C31.186 12.406 35 17.757 35 24zM20.318 9.003A11.98 11.98 0 0 1 21 13c0 6.627-5.373 12-12 12a11.98 11.98 0 0 1-3.997-.682A15.31 15.31 0 0 1 5 24c0-8.284 6.716-15 15-15l.318.003z\" fill=\"#fff\" fill-rule=\"evenodd\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1Ji0q.framer-mwuc9u, .framer-1Ji0q .framer-mwuc9u { display: block; }", ".framer-1Ji0q.framer-t4kory { height: 37px; overflow: visible; position: relative; width: 31px; }", ".framer-1Ji0q .framer-1kunpv4 { aspect-ratio: 0.8333333333333334 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 37px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 37
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJogcytxEM: React.ComponentType<Props> = withCSS(Component, css, "framer-1Ji0q") as typeof Component;
export default FramerJogcytxEM;

FramerJogcytxEM.displayName = "Logo";

FramerJogcytxEM.defaultProps = {height: 37, width: 31};

addFonts(FramerJogcytxEM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})